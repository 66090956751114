import Button from "components/Button";
import { DotsLoader } from "components/DotsLoader";
import HeaderBar from "components/HeaderBar";
import { extractUTMParameters, useCancelDispatch, useGetData } from "helpers";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
    SmsInfoType,
    smsTypeAndCount,
} from "routes/CampaignRoute/sections/section_3/helpers";
import StatCard from "routes/DashboardRoute/StatCard";
import AccountContext from "store/account-context";
import DispatchContext from "store/dispatch-context";
import { useGroupData } from "store/group-context";
import { ShortlinksContext } from "store/shortlink-context";

import "./index.css";

type IndividualDispatch = Dispatch & {
    totalCost?: {
        total: number;
    };
    estimatedCost?: number;
    splits?: number;
    volume?: number;
    completed_splits?: number;
    stats?: any;
    shortlink_used?: any;
    shortlink?: any;
};

const utcDateStringToLocalDate = (input: string) => {
    const [dateStr, timeStr] = input.split(' ');
    const [year, month, day] = dateStr.split('-');
    const [hour, minutes] = timeStr.split(':');
    const utcDate = Date.UTC(
        Number(year),
        Number(month)-1,
        Number(day),
        Number(hour),
        Number(minutes),
    )
    return new Date(utcDate);
}

const dateAndTimeString = (inputDate: string) => {
    const date = utcDateStringToLocalDate(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minute}`;
};

const IndividualCampaignRoute = () => {
    let { id: campaignId } = useParams();
    const groupsData: any = useGroupData();
    const cancelDispatch = useCancelDispatch();
    const remainingTimeRef = useRef<HTMLParagraphElement | null>(null);
    const {
        getData: getDCost,
        isLoading: isGetDCostLoading,
        error: getDCostError,
        data: getDCostData,
    } = useGetData();

    const {
        getData: getGCC,
        isLoading: isGetGCC,
        error: getGCCError,
        data: getGCCData,
    } = useGetData();

    const {
        getData: getDStats,
        isLoading: isGetDStatsLoading,
        error: getDStatsError,
        data: getDStatsData,
    } = useGetData();

    const {
        getData: getReport,
        isLoading: isGetReportLoading,
        error: getReportError,
        data: getReportData,
    } = useGetData();

    const accountCtx = useContext(AccountContext);
    const {
        dispatches: allCampaigns,
        loading: refreshing,
        updateCampaign,
    } = useContext(DispatchContext);
    const shortlinksCtx = useContext(ShortlinksContext);

    const [campaignData, setCampaignData] = useState<IndividualDispatch>();
    const [campaignStats, setCampaignStats] = useState<any>();
    const [targetGroups, setTargetGroups] = useState<any>();
    const [blockedGroups, setBlockedGroups] = useState<any[]>([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [copyBtnClick, setCopyBtnClick] = useState(false);

    const intervalRef = useRef<number | null>(null);

    const [smsInfo, setSmsInfo] = useState<SmsInfoType>({
        charCount: 0,
        parts: 0,
        maxLengthForParts: 0,
        specialChars: [],
        isUnicode: false,
    });
    
    const volumeTitle = campaignData?.state === 'sending'? 'Total volume' : 'Estimated volume';
    let volumeString = '-';
    let deliveredString = "-";
    if (campaignData?.state === 'sending') {
        const textVolume = Object.values(campaignData.texts).reduce((sum, val) => sum+val, 0)
        const partsVolume = Object.values(campaignData.parts).reduce((sum, val) => sum+val, 0)
        volumeString = partsVolume.toString();
        const deliveredPercent = campaignData?.texts?.delivered? campaignData.texts.delivered / textVolume : 0;
        deliveredString = (100 * deliveredPercent).toFixed(1).toString() + "%";
    } else if (campaignData?.volume) {
        volumeString = campaignData.volume.toString()
    }

    let revenue = campaignData?.ga_revenue;
    let currency = campaignData?.ga_currency;
    const revenueString = revenue? `${revenue.toFixed(0)} ${currency}`: "N/A";
    let epmString = '';
    let epmDescription = ''
    if (revenue && campaignData?.texts?.delivered) {
        const epm = revenue / campaignData.texts.delivered;
        epmString = `${epm.toFixed(2)} ${currency}`;
        epmDescription = '(EPM)'
    }
    let aovString = '';
    let aovDescription = ''
    if (revenue && campaignData?.ga_transactions) {
        const epm = revenue / campaignData.ga_transactions;
        aovString = `${epm.toFixed(0)} ${currency}`;
        aovDescription = '(AOV)'
    }

    let transactions = campaignData?.ga_transactions;
    let clicks = campaignData?.clicks;
    let transactionsString = `${transactions || 'N/A'}`;
    const conversionString = (transactions && clicks)? `${(transactions/clicks*100).toFixed(1)} %`: "N/A";
    
    const completedSplits = campaignData?.state === 'sending' ? campaignData?.splits : campaignData?.completed_splits;
    
    const handleGetDCost = (dispatchNumber: any) => {
        getDCost(
            process.env.REACT_APP_API_BASE +
                "/dispatches/" +
                dispatchNumber +
                "/cost",
            (fetchedData) => {
                setCampaignData((oldData: any) => ({
                    ...oldData,
                    totalCost: fetchedData.cost,
                }));
            }
        );
        if (getDCostError) {
        }
    };

    const handleGetGCC = (targetCampaign: Dispatch) => {
        getGCC(
            process.env.REACT_APP_API_BASE +
                "/groups/" +
                [...targetCampaign.group_ids, ...targetCampaign.tmp_blocklist.map(x =>-x)].join(",") + 
                "/countrycodes",
            (fetchedData) => {
                let sum = 0;
                let cc: {[key: string]: number} = fetchedData.countrycodes;
                Object.keys(cc).forEach((countryCode) => {
                    if (countryCode in accountCtx.prices) {
                        sum += accountCtx.prices[countryCode] * cc[countryCode];
                    } else {
                        sum += accountCtx.prices["default"] * cc[countryCode];
                    }
                });

                let newSmsInfo = smsTypeAndCount(targetCampaign?.text ?? "");
                const texts = Object.values(cc).reduce((acc, x) => acc + x, 0);
                const parts = texts * newSmsInfo.parts;
                
                setCampaignData((oldData: any) => ({
                    ...oldData,
                    volume: parts,
                    estimatedCost: sum,
                }));
            }
        );
        if (getDCostError) {
        }
    };

    const handleGetReport = (id: string) => {
        getReport(
            process.env.REACT_APP_API_BASE + "/dispatches/" + id + "/stats",
            (fetchedData) => {
                if (
                    fetchedData.filename != null &&
                    fetchedData.filename.length > 0
                ) {
                    window.open(
                        "https://app.jojka.com/tmp/" + fetchedData.filename
                    );
                }
                else if (fetchedData.url != null) {
                    window.open(fetchedData.url);
                }
            }
        );
    };

    const handleCancel = (data: any) => {
        campaignData?._id &&
            cancelDispatch(campaignData?.number, (data: any) => undefined);
        campaignData?._id &&
            updateCampaign(campaignData?._id, { state: "cancelled" });
    };

    function calculateRemainingHoursAndMinutes(targetDateString: any) {
        const currentDate = new Date();
        const targetDate = new Date(targetDateString + " UTC");
        const timeDifference = targetDate.getTime() - currentDate.getTime();

        if (timeDifference <= 0) {
            return "00:00";
        }

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );

        if (days > 0) {
            return `${days} day${days > 1 ? "s" : ""} and ${hours} hour${
                hours > 1 ? "s" : ""
            }`;
        } else {
            return `${hours} hours ${minutes} minutes`;
        }
    }

    useEffect(() => {
        if (allCampaigns && !refreshing) {
            const targetCampaign: any = allCampaigns.find((dispatch: any) => {
                return dispatch._id === campaignId;
            });
            let parsedMessage = "";
            if (targetCampaign) {
                setCampaignData((oldData: any) => ({
                    ...oldData,
                    ...targetCampaign,
                }));
                setCampaignStats(
                    targetCampaign?.stats ? targetCampaign.stats : {}
                );
                handleGetDCost(targetCampaign?.number);
                handleGetGCC(targetCampaign);

                parsedMessage = targetCampaign.text;
                if (targetCampaign.shortlink_used) {
                    let shortlink: any = shortlinksCtx?.shortlinks.find(
                        (findShortlink: any) =>
                            (findShortlink.active === true &&
                            findShortlink.short_domain ===
                            targetCampaign.shortlink_used.split("/")[0]
                            &&
                            findShortlink.short_code ===
                            targetCampaign.shortlink_used.split("/")[1])
                    );
                    if (!shortlink) {
                        window.location.reload()
                    } else {
                        shortlink = {
                            ...shortlink,
                            ...extractUTMParameters(shortlink.target_url),
                        };
                        let shortUrl = shortlink.short_domain + '/' + shortlink.short_code;
                        parsedMessage = parsedMessage.replaceAll("%link%", shortUrl);
                    }
                    setCampaignData((oldData: any) => ({
                        ...oldData,
                        shortlink,
                    }));
                }
            }

            let newSmsInfo = smsTypeAndCount(parsedMessage);
            setSmsInfo(newSmsInfo);
        }
    }, [allCampaigns, refreshing]);

    useEffect(() => {
        if (!groupsData.loading && smsInfo?.hasOwnProperty("charCount")) {
            let newTargetGroups = groupsData.groups.filter((group: any) =>
                campaignData?.group_ids.includes(group.id)
            );
            let newBlockedGroups = groupsData.groups.filter((group: any) =>
                campaignData?.tmp_blocklist.includes(group.id)
            );
            setTargetGroups(newTargetGroups);
            setBlockedGroups(newBlockedGroups);
            setPageLoading(false);
        }
    }, [groupsData.loading, smsInfo]);

    useEffect(() => {
        const updateRemainingTime = () => {
            if (remainingTimeRef.current) {
                const remainingTime = calculateRemainingHoursAndMinutes(
                    campaignData?.date
                );
                remainingTimeRef.current.textContent = remainingTime;
            }
        };

        // Calculate and update the remaining time initially
        updateRemainingTime();

        // Set up an interval to update the remaining time every minute
        intervalRef.current = window.setInterval(updateRemainingTime, 60000); // Update every minute (60,000 milliseconds)

        // Clean up the interval on component unmount
        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const handleCopyBtnClick = async () => {
        if (campaignData?.text) {
            try {
                if (navigator.clipboard) {
                    await navigator.clipboard.writeText(campaignData?.text);
                }
                setCopyBtnClick(true);

                setTimeout(() => {
                    setCopyBtnClick(false);
                }, 1500);
            } catch (err) {
                console.error("Failed to copy: ", err);
            }
        }
    };

    return (
        <>
            <HeaderBar
                title={
                    (!refreshing && (
                        <p className="text-lg font-medium normal-case">
                            Campaign #{campaignData?.number} -{" "}
                            {campaignData?.name}
                        </p>
                    )) ||
                    (refreshing && (
                        <p className="flex items-center gap-2">
                            Campaign <DotsLoader />{" "}
                        </p>
                    ))
                }
            ></HeaderBar>
            <div className="pageBeginMargin h-full flex flex-col w-9/12 items-center bg-whiteSmoke">
                <div className="w-full bg-whiteSmoke">
                    {!pageLoading ? (
                        <>
                            <div className="border-b border-granitine pb-8 ">
                                <div className="flex items-center justify-between mb-8">
                                    <div className="">
                                        <p className="text-lg font-semibold mb-2">
                                            Status:{" "}
                                            {(campaignData?.state ===
                                                "cancelled" &&
                                                "Cancelled") ||
                                                (campaignData?.state ===
                                                    "scheduled" &&
                                                    `Scheduled for ${dateAndTimeString(
                                                        campaignData?.date
                                                    )}`) ||
                                                "Sent"}
                                        </p>
                                        <p className="text-base">
                                            Our detailed analysis feature will
                                            empower you with a deeper
                                            understanding of your campaign's
                                            performance.{" "}
                                        </p>
                                    </div>
                                    {campaignData?.state === "scheduled" ? (
                                        <Button
                                            handleClick={handleCancel}
                                            text="Cancel"
                                            bgColor="bg-briquette"
                                            textColor="white"
                                            style="w-12"
                                        ></Button>
                                    ) : undefined}
                                </div>
                                <div className="grid grid-cols-4 gap-6">
                                    <StatCard
                                        title={"Revenue"}
                                        value={revenueString}
                                        diff={epmString}
                                        diffDesc={epmDescription}
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 text-birchwood"
                                            >
                                                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                    />
                                    <StatCard
                                        title={"Click-rate"}
                                        value={
                                            campaignData?.clicks
                                            ?
                                            (
                                                (campaignData.clicks /
                                                    campaignData.texts.delivered
                                                ) *
                                                100
                                            ).toFixed(1) + "%"
                                            : "N/A"
                                        }
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 text-birchwood"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                    />
                                    <StatCard
                                        title={"Conversion rate"}
                                        value={conversionString}
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 text-birchwood"
                                            >
                                                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                    />
                                    <StatCard
                                        title={"Placed order"}
                                        value={transactionsString}
                                        diff={aovString}
                                        diffDesc={aovDescription}
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 text-birchwood"
                                            >
                                                <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875z" />
                                                <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 001.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 001.897 1.384C6.809 12.164 9.315 12.75 12 12.75z" />
                                                <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 15.914 9.315 16.5 12 16.5z" />
                                                <path d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 19.664 9.315 20.25 12 20.25z" />
                                            </svg>
                                        }
                                    />
                                    <StatCard
                                        title={volumeTitle}
                                        value={volumeString}
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 text-birchwood"
                                            >
                                                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                            </svg>
                                        }
                                    />
                                    <StatCard
                                        title={"Delivered"}
                                        value={deliveredString}
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 text-birchwood"
                                            >
                                                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                            </svg>
                                        }
                                    />
                                    <StatCard
                                        title={
                                            campaignData?.state === "scheduled"
                                                ? "Estimated Cost"
                                                : "Cost"
                                        }
                                        value={
                                            campaignData?.state !== "scheduled"
                                                ? !isGetDCostLoading &&
                                                  getDCostData
                                                    ? `${(
                                                          getDCostData.cost
                                                              .total / 10000
                                                      ).toFixed(2)} ${
                                                          accountCtx.billingCurrency
                                                      }`
                                                    : "-"
                                                : campaignData?.estimatedCost
                                                ? `${(
                                                      campaignData?.estimatedCost /
                                                      10000
                                                  ).toFixed(2)} ${
                                                      accountCtx.billingCurrency
                                                  }`
                                                : "0 " +
                                                  accountCtx.billingCurrency
                                        }
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 text-birchwood"
                                            >
                                                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                    />
                                    <StatCard
                                        title={"Batch sending"}
                                        value={
                                            campaignData?.splits
                                                ? `${completedSplits ?? "0"
                                                }/${campaignData?.splits}`
                                                : "N/A"
                                        }
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 text-birchwood"
                                            >
                                                <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875z" />
                                                <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 001.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 001.897 1.384C6.809 12.164 9.315 12.75 12 12.75z" />
                                                <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 15.914 9.315 16.5 12 16.5z" />
                                                <path d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 19.664 9.315 20.25 12 20.25z" />
                                            </svg>
                                        }
                                    />
                                </div>
                            </div>
                            <div className="pb-2 pt-4">
                                <div className="bg-white sectionShadow rounded-default p-8 ">
                                    <div className="flex w-full gap-8">
                                        <p className="font-semibold mb-8 text-base w-1/2">
                                            Content
                                        </p>
                                    </div>
                                    <div className="flex gap-8 w-full">
                                        <div className="flex flex-col w-1/2">
                                            <div className="relative border border-granitine rounded-default p-4 w-full mb-4 min-h-[13rem]">
                                                {campaignData?.state ? (
                                                    <div className="absolute inline-flex top-0 right-0 p-2">
                                                    <Button
                                                        handleClick={handleCopyBtnClick}
                                                        bgColor="bg-tamahagane"
                                                        textColor="white"
                                                        style="!min-w-0"
                                                        renderText={() =>
                                                            !copyBtnClick ? (
                                                                <>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth={
                                                                            1.5
                                                                        }
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6 text-white"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                                                                        />
                                                                    </svg>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth={
                                                                            1.5
                                                                        }
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6 text-white"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M4.5 12.75l6 6 9-13.5"
                                                                        />
                                                                    </svg>

                                                                    <p className="text-white text-base ml-2">
                                                                        Text copied!
                                                                    </p>
                                                                </>
                                                            )
                                                        }
                                                    ></Button>
                                                    </div>
                                                ) : (
                                                    <div className="h-12"></div>
                                                )}
                                                <p className="text-wrap text-base pr-10 whitespace-pre-line">
                                                    {campaignData?.text}
                                                </p>
                                                
                                                
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="flex">
                                                    <p className="text-base whitespace-pre font-semibold">
                                                        Characters
                                                    </p>
                                                    <div className="w-26 flex">
                                                        <p
                                                            className={`text-base opacity-70 font-normal ml-1 `}
                                                        >
                                                            {`${smsInfo.charCount} / ${smsInfo.maxLengthForParts}`}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="flex">
                                                    <p className="text-base font-semibold">
                                                        SMS
                                                    </p>
                                                    <p className="text-right ml-2 text-base font-normal opacity-70">
                                                        {smsInfo.parts}
                                                    </p>
                                                </div>
                                                
                                                <p className="text-base font-semibold col-span-1 truncate">
                                                    Special chars
                                                    <span className="font-normal text-base opacity-70 ">
                                                        {" "}
                                                        {smsInfo.specialChars
                                                            .length === 0 && "None"}
                                                        {smsInfo.specialChars
                                                                .length <= 3 &&
                                                            smsInfo.specialChars.join(
                                                                ""
                                                            )}
                                                        {smsInfo.specialChars
                                                                .length > 3 &&
                                                            smsInfo.specialChars
                                                                .slice(0, 3)
                                                                .join("") +
                                                            " +" +
                                                            (smsInfo
                                                                    .specialChars
                                                                    .length -
                                                                3)}
                                                     </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white sectionShadow rounded-default p-8 mt-4 relative">
                                    <p className="font-semibold mb-6 text-base">
                                        Recipients
                                    </p>
                                    <div className="w-1/2 flex flex-col">
                                        <div className="flex flex-col mb-4 ">
                                            <p className="font-semibold text-base">
                                                Send to:
                                            </p>
                                            {targetGroups.length ? (
                                                <p className="mb-4 text-base">
                                                    {targetGroups &&
                                                        targetGroups
                                                            .map(
                                                                (
                                                                    group: any
                                                                ) =>
                                                                    `${group.name} (${group.size})`
                                                            )
                                                            .join(", ")}
                                                </p>
                                            ) : (
                                                <div className="mb-2 py-2">
                                                    <DotsLoader />
                                                </div>
                                            )}
                                            <p className="font-semibold text-base">
                                                Don't send to:
                                            </p>
                                            {blockedGroups.length ? (
                                                <p className="text-base">
                                                    {blockedGroups
                                                        .map((group: any ) => `${group.name} (${group.size})`)
                                                        .join(", ")}
                                                </p>
                                            ) : (
                                                <p className="text-base font-semibold text-gunMetal/40">
                                                    Not applicable
                                                </p>
                                            )}
                                        </div>
                                        {campaignData?.state &&
                                        [
                                            "split_sent",
                                            "sent",
                                            "sending",
                                        ].includes(campaignData?.state)? (
                                            <div className="absolute top-8 right-4">
                                                <Button
                                                    handleClick={() =>
                                                        handleGetReport(
                                                            campaignData?.number.toString()
                                                        )
                                                    }
                                                    text="Download Report"
                                                    bgColor="bg-tamahagane"
                                                    textColor="white"
                                                ></Button>
                                            </div>
                                        ) : undefined}
                                    </div>
                                </div>
                                <div className="bg-white sectionShadow rounded-default p-8 mt-4 min-h-[13rem]">
                                    <p className="font-semibold mb-6 text-base">
                                        Settings
                                    </p>
                                    {campaignData?.state === "split_sent" ? (
                                        <div className="text-base flex items-center gap-2">
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    className="w-6 h-6 text-decoGray"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </span>
                                            <p className="text-base">
                                                Batch Sending
                                            </p>
                                        </div>
                                    ) : (
                                        <p className="font-semibold text-base text-gunMetal/40">
                                            No special settings
                                        </p>
                                    )}
                                </div>
                                <div className="flex gap-6 mt-4">
                                    <div className="bg-white sectionShadow rounded-default p-8 w-full min-h-[13rem]">
                                        <p className="font-semibold mb-6 text-base">
                                            Tracking
                                        </p>
                                        {campaignData?.shortlink ? (
                                            <div className=" flex flex-col">
                                                <div className="mb-2 row-span-1">
                                                    <p className="text-base font-semibold">
                                                        Shortlink:
                                                    </p>
                                                    <p className="text-base">
                                                        {
                                                            campaignData?.shortlink_used
                                                        }
                                                    </p>
                                                </div>
                                                <div className="mb-2 col-span-2">
                                                    <p className="text-base font-semibold">
                                                        Target url:
                                                    </p>
                                                    <div className="pr-4 rounded-md">
                                                        <p className="text-base overflow-auto whitespace-nowrap no-scrollbar target-url">
                                                            {
                                                                campaignData?.shortlink?.target_url ?
                                                                campaignData
                                                                    ?.shortlink
                                                                    ?.target_url
                                                                    : "-"
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                {campaignData.shortlink
                                                    .utm_id ? (
                                                    <div className="mb-2 col-span-1">
                                                        <p className="text-base font-semibold">
                                                            Utm id:
                                                        </p>
                                                        <p className="text-base">
                                                            {
                                                                campaignData
                                                                    ?.shortlink
                                                                    .utm_id
                                                            }
                                                        </p>
                                                    </div>
                                                ) : undefined}
                                                {campaignData.shortlink
                                                    .utm_source ? (
                                                    <div className="mb-2 col-span-2">
                                                        <p className="text-base font-semibold">
                                                            Utm source:
                                                        </p>
                                                        <p className="text-base">
                                                            {
                                                                campaignData
                                                                    ?.shortlink
                                                                    .utm_source
                                                            }
                                                        </p>
                                                    </div>
                                                ) : undefined}
                                                {campaignData.shortlink
                                                    .utm_medium ? (
                                                    <div className="mb-2 col-span-1 place-self-start">
                                                        <p className="text-base font-semibold">
                                                            Utm medium:
                                                        </p>
                                                        <p className="text-base">
                                                            {
                                                                campaignData
                                                                    ?.shortlink
                                                                    .utm_medium
                                                            }
                                                        </p>
                                                    </div>
                                                ) : undefined}
                                                {campaignData.shortlink
                                                    .utm_campaign ? (
                                                    <div className="mb-2 col-span-2">
                                                        <p className="text-base font-semibold">
                                                            Utm campaign:
                                                        </p>
                                                        <p className="text-base">
                                                            {
                                                                campaignData
                                                                    ?.shortlink
                                                                    .utm_campaign
                                                            }
                                                        </p>
                                                    </div>
                                                ) : undefined}
                                            </div>
                                        ) : (
                                            <p className="text-base text-gunMetal/40 font-semibold">
                                                No shortlinks were used in this
                                                campaign.
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <p className="text-lg text-center mt-72">
                            Loading campaign...
                        </p>
                    )}
                </div>
            </div>
        </>
    );
};

export default IndividualCampaignRoute;
